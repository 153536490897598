<template>
  <div class="section-row -padded-wide">
    <div class="col-wrapper l-2col l-2col-b2-33-66 l-2col-b2-33-66--normal -max-width-limited">
      <div class="col col-one sidebar">
        <div class="module-nav module-nav--categories">
          <ul class="module-nav__lvl1">
            <li class="module-nav__item" v-for="(category, index) in categories" :key="index">
              <a :href="category.path">{{ category.name }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col col-two content">
        <div class="module-product-list" style="display: flex; justify-content: space-between;">
          <div class="product-list__product" v-for="(product, index) in products" :key="index" style="width: 31%;">
            <a :href="product.path">
              <div class="product__image" style="margin-bottom: 1em;">
                <img src="https://via.placeholder.com/400x300" alt="Produkt-Bild">
              </div>
              <div class="product__name">
                <span class="heading delta">{{ product.name }}</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([
      'categories',
      'products'
    ])
  }
}
</script>
