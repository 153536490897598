import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    categories: [
      {
        name: 'Kategorie 1',
        path: '/kategorie-1'
      },
      {
        name: 'Kategorie 2',
        path: '/kategorie-2'
      },
      {
        name: 'Kategorie 3',
        path: '/kategorie-3'
      }
    ],
    products: [
      {
        name: 'Produkt 1',
        path: '/p/produkt-1'
      },
      {
        name: 'Produkt 2',
        path: '/p/produkt-2'
      },
      {
        name: 'Produkt 3',
        path: '/p/produkt-3'
      }
    ]
  }
})
